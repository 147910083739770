<template>
  <div class="app-container">
    <div class="filter-container">
      学校:
      <el-select
        v-model="listQuery.school_id"
        placeholder="请选择学校"
        style="width: 150px"
        class="filter-item"
      >
        <el-option
          v-for="item in school_list"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        ></el-option>
      </el-select>
      学生姓名/学号:
      <el-input
        v-model="listQuery.nickname"
        placeholder="请输入学生姓名/学号"
        style="width: 200px"
        class="filter-item"
        clearable
      />
      题目:
      <el-input
        v-model="listQuery.title"
        placeholder="请输入题目"
        style="width: 200px"
        class="filter-item"
        clearable
      />
      答题时间:
      <el-date-picker
        class="filter-item"
        v-model="dateArr"
        type="datetimerange"
        :default-time="['00:00:00', '23:59:59']"
        align="right"
        value-format="yyyy-MM-dd HH:mm:ss"
        unlink-panels
        range-separator="至"
        start-placeholder="开始时间"
        end-placeholder="结束时间"
      ></el-date-picker>
      <el-button class="filter-item" type="primary" @click="handleFilter"
        >搜索</el-button
      >
    </div>

    <el-table
      v-loading="listLoading"
      :data="list"
      element-loading-text="Loading"
      border
      fit
      highlight-current-row
    >
      <el-table-column label="序号" width="95" align="center">
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column label="学校" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.student.college.school.name }}
        </template>
      </el-table-column>
      <el-table-column label="学生ID" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.student.id }}
        </template>
      </el-table-column>
      <el-table-column label="学生姓名" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.student.name }}
        </template>
      </el-table-column>
      <el-table-column label="学号" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.student.account }}
        </template>
      </el-table-column>
      <el-table-column label="院系" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.student.college.name }}
        </template>
      </el-table-column>
      <el-table-column label="题目" min-width="110" align="center">
        <template slot-scope="scope"
          >{{ scope.row.student_question_bank.title }}
        </template>
      </el-table-column>
      <el-table-column label="答案" min-width="110" align="center">
        <template slot-scope="scope">
          <span
            v-for="item in scope.row.student_question_bank.answer
              ? scope.row.student_question_bank.answer.split(',')
              : []"
            :key="item"
          >
            {{ item >= 65 ? String.fromCharCode(item) : item }}
          </span></template
        >
      </el-table-column>
      <el-table-column label="答题时间" min-width="110" align="center">
        <template slot-scope="scope">{{ scope.row.answer_at }} </template>
      </el-table-column>
      <el-table-column
        label="操作"
        width="200"
        align="center"
        class-name="small-padding fixed-width"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="primary"
            @click="handleDetail(scope.row)"
          >
            查看详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination-container">
      <el-pagination
        :current-page="listQuery.page"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="listQuery.limit"
        :total="total"
        background
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>

    <el-dialog
      title="查看详情"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
    >
      <el-form
        ref="form"
        :rules="rules"
        :model="form"
        label-width="160px"
        v-if="form.answer_at"
      >
        <el-form-item label="学校:" prop="link_to">
          {{ form.student.college.school.name }}
        </el-form-item>
        <el-form-item label="学生ID:" prop="cover_url">
          {{ form.student.id }}
        </el-form-item>
        <el-form-item label="学生姓名:" prop="cover_url">
          {{ form.student.name }}
        </el-form-item>
        <el-form-item label="题目:" prop="weight">
          {{ form.student_question_bank.title }}
        </el-form-item>
        <el-form-item label="答案:" prop="weight">
          {{ form.student_question_bank.answer }}
        </el-form-item>
        <el-form-item label="答题时间:" prop="weight">
          {{ form.answer_at }}
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <!-- <el-button @click="dialogFormVisible = false">取消</el-button> -->
        <el-button
          type="primary"
          @click="dialogFormVisible = false"
          :loading="btnLoading"
        >
          确定
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import request from "@/utils/request";

export default {
  data() {
    return {
      list: [{ name: "jiao" }],
      school_list: [],
      total: null,
      listLoading: false,
      dateArr: null,
      listQuery: {
        page: 1,
        limit: 10,
        start_at: "",
        end_at: "",
        nickname: "",
        title: "",
        comment_content: "",
      },
      dialogFormVisible: false,
      form: {
        user_id: "",
        forum_post_message_id: "", //评论id
        content: "", //提醒内容
      },
      rules: {
        name: [{ required: true, message: "院系不能为空", trigger: "change" }],
      },
      btnLoading: false,
    };
  },
  created() {
    this.getList();
    this.getSchoolList();
  },
  watch: {
    dateArr(newVal, oldVal) {
      if (newVal) {
        this.listQuery.start_at = newVal[0];
        this.listQuery.end_at = newVal[1];
      } else {
        this.listQuery.start_at = "";
        this.listQuery.end_at = "";
      }
    },
  },
  methods: {
    getSchoolList() {
      request({
        url: "/api/teacherend/school/list",
        method: "get",
        params: { limit: 99999999999999 },
      }).then((response) => {
        this.school_list = response.data.data;
      });
    },
    getList() {
      this.listLoading = true;
      request({
        url: "/api/teacherend/studentQuestionBank/answerLog",
        method: "get",
        params: this.listQuery,
      }).then((response) => {
        this.list = response.data.data;
        this.total = response.data.total;
        this.listLoading = false;
      });
    },
    handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    resetForm() {
      this.form = {
        id: "",
        name: "",
      };
    },
    handleDetail(row) {
      this.resetForm();
      this.form = Object.assign({}, row);
      this.dialogFormVisible = true;
      this.$nextTick(() => {
        this.$refs["form"].clearValidate();
      });
      //   this.getDetail(row.id);
    },
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
</style>
